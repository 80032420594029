import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["nav"];

  toggle() {
    for (let el of this.navTargets) {
      el.classList.toggle("hidden");
    }
  }

  open(event) {
    event.preventDefault();
    event.stopPropagation();

    let target = event.target.dataset.key;
    let el = document.getElementById(target);
    el.classList.toggle("hidden");

    // icon rotate
    let icon = document.getElementById(`${el.id}-${'svg'}`);
    if (icon != undefined) {
      icon.classList.toggle("icon-rotate");
    }
  }
}