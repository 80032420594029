import { Controller } from "stimulus";
import Rails from "@rails/ujs";

const TARGETS = [
  "title"
];

export default class extends Controller {
  static targets = TARGETS;

  initialize() {
    /*this.change = _.debounce(this.change, 500);*/
    this.parseURLParams();
  }

  change() {
    this.submit();
  }

  select() {
    this.submit();
  }

  reset(event) {
    if (event.target.value.length === 0) {
      window.location.replace(location.pathname);
    }
  }

  submit() {
    // Avoid fire invalid forms for the server
    if (!this.element.checkValidity()) { return; }
    // Searching ...
    var count = document.getElementById("count");
    if (count) { count.innerText = 'Searching...'; }
    // Submit element
    Rails.fire(this.element, "submit");
    window.history.pushState(
      { turbolinks: {} },
      "",
      `${window.location.pathname}?${Rails.serializeElement(this.element)}`
    );
  }

  // Conserve the input values when searching for something and
  // then paginating results.
  parseURLParams() {
    const params = new URLSearchParams(window.location.search);

    TARGETS.forEach(target => {
      const element = this.targets.find(target);

      if (element && params.get(target)) {
        element.value = params.get(target);
      }
    });
  }
}