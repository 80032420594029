import Carousel from "stimulus-carousel"

export default class extends Carousel {
  connect() {
    this.swiper

    // Default options for every carousels.
    this.defaultOptions
  }

  // You can set default options in this getter.
  get defaultOptions() {
    return {
      autoplay: {
        delay: 5000,
      },
      // loop: true,
      // speed: 35000,
      // freeMode: true,
      // slideToClickedSlide: true,
      // centeredSlides: false,
      // slidesPerView: 'auto',
      // initialSlide: -1,
      // observer: true,
      // loopPreventsSlide: false,
      // autoplay: {
      //   delay: 0,
      //   disableOnInteraction: false,
      //   loopPreventsSlide: false,
      // },
    }
  }
}